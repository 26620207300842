import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => {
  return ({
    image: {
      width:'100%'
    },
    main: {
      overflow:'hidden',
      '& h1': {
        color: theme.palette.primary.main,
        fontSize: '2rem',
      },
      '& h2': {
        color: theme.palette.primary.main,
        fontSize: '1.5rem',
        textTransform: 'uppercase',
      },
      '& h3': {
        color: theme.palette.primary.main,
        fontSize: '1.4rem',
        textTransform: 'uppercase',
      },
      '& h4': {
        color: theme.palette.primary.main,
        fontSize: '1.2rem',
        textTransform: 'uppercase',
      },
      '& li': {
        fontSize: '1.5rem',
      },
      '& p': {
        fontSize: '1.5rem',
        lineHeight: '1.8rem',
        '& strong': {
          color: theme.palette.primary.main,
          fontWeight: '700',
        },
        '& em': {
          color: theme.palette.primary.main,
          fontWeight: '700',
        }
      },
    }
  })
})

export default useStyles